import { createSlice } from '@reduxjs/toolkit';
import {DentalFormula, DentalFormulaVersion, Status, Tooth} from "../../@types/dentalFormula";

// ----------------------------------------------------------------------

type FormulaState = {
    isLoading: boolean;
    error: boolean;
    versionList: DentalFormulaVersion[];
    clientFormula?: DentalFormula;
    toothList: Tooth[];
    statusList: Status[];
};

const initialState: FormulaState = {
    isLoading: false,
    error: false,
    versionList: [],
    toothList: [],
    statusList: [],
};

const slice = createSlice({
    name: 'formula',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getVersionListSuccess(state, action) {
            state.isLoading = false;
            state.versionList = action.payload;
        },

        getFormulaSuccess(state, action) {
            state.isLoading = false;
            state.clientFormula = action.payload;
        },

        addFormulaItem(state, action) {
            if(state.clientFormula) {
                state.clientFormula.items = state.clientFormula.items.concat([action.payload]);
            }
        },

        deleteFormulaItem(state, action) {
            if(state.clientFormula) {
                state.clientFormula.items = state.clientFormula.items.filter(f => f.tooth.id !== action.payload.tooth.id || f.status.id !== action.payload.status.id || f.part !== action.payload.part);
            }
        },

        getToothListSuccess(state, action) {
            state.isLoading = false;
            state.toothList = action.payload;
        },

        getStatusListSuccess(state, action) {
            state.isLoading = false;
            state.statusList = action.payload;
        },
    }
});

export const {
    startLoading, hasError,
    getVersionListSuccess, getFormulaSuccess, addFormulaItem, deleteFormulaItem,
    getToothListSuccess, getStatusListSuccess
} = slice.actions;

export default slice.reducer;


