import {AppThunk} from "../store";
import {DentalFormula, DentalFormulaItem, DentalFormulaVersion} from "../../@types/dentalFormula";
import {
    getFormulaSuccess,
    getStatusListSuccess,
    getToothListSuccess,
    getVersionListSuccess,
    hasError,
    startLoading
} from "../slices/formula";
import ApiClients from "../../utils/axios";

const { axiosBase } = ApiClients;

export const getToothListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get('/dental/teeth');
        dispatch(getToothListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getStatusListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get('/dental/statuses');
        dispatch(getStatusListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getClientFormulaVersionListThunk = (
    client_id: number,
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get<DentalFormulaVersion[]>(`/dental/versions?client_id=${client_id}`);
        dispatch(getVersionListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const loadClientFormulaThunk = (
    client_id: number,
    version_id?: number,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const versionParam = version_id ? `&version_id=${version_id}` : ''
        const response = await axiosBase.get<DentalFormula>(`/dental/formula?client_id=${client_id}${versionParam}`)
        dispatch(getFormulaSuccess(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}

export const saveClientFormulaThunk = (
    client_id: number,
    items: DentalFormulaItem[]
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axiosBase.post<DentalFormula>(`/dental/formula`, {
            client_id,
            items: items.map(f => ({
                id: f.id,
                tooth_id: f.tooth.id,
                part: f.part,
                status_id: f.status.id,
                time: f.time,
            }))
        })
        dispatch(getFormulaSuccess(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}
